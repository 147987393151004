'use client'

import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { SessionProvider, signIn, signOut, useSession } from 'next-auth/react'
import React, { Fragment } from 'react'
import { urls } from '../../../urls'
import { Button } from '../Button'
import { InternalLink } from '../Link'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../DropdownMenu'

const _SessionNavDropdownClient: React.FC = () => {
  const { data: session, status } = useSession()
  if (status === 'loading') {
    return null
  }
  if (!session?.user) {
    return (
      <Button className="pr-0" size="sm" variant="link" onClick={() => signIn('twitter')}>
        Sign in to add your own analysis
      </Button>
    )
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="inline-flex justify-center gap-x-1.5 rounded-md bg-transparent py-2 pl-3 pr-0 text-sm font-semibold text-link ring-inset ring-gray-300 hover:ring-1">
          {session.user.name}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuGroup>
          <DropdownMenuItem>
            <InternalLink
              href={urls.userHomepage()}
              className={classNames('text-gray-700', 'block px-4 py-2 text-sm')}
            >
              Your Analysis
            </InternalLink>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <a
              href="#"
              onClick={() => signOut()}
              className={classNames('text-gray-700', 'block px-4 py-2 text-sm')}
            >
              Log Out
            </a>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const SessionNavDropdownClient: React.FC = () => {
  return (
    <SessionProvider>
      <_SessionNavDropdownClient />
    </SessionProvider>
  )
}
